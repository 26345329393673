<template>
  <div>
    <div class="card">
      <div class="my-4">
        <Button label="Atras"
                icon="pi pi-arrow-left"
                class="mr-2 p-button-danger"
                @click="routerBack"
        />
        <Button label="Nuevo"
                icon="pi pi-plus"
                class="mr-2"
                @click="openNew"
                v-if="$can('pharmasan.oportunidades.licitaciones.herramientas.documentos.access')"
        />
      </div>

      <DataTable ref="dt"
                 :value="documents"
                 dataKey="id"
                 responsiveLayout="scroll"
                 class="p-datatable-sm"
      >
        <template #header>
          <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
            <h5 class="mb-2 md:m-0 p-as-md-center">
              Administrar Documentos
            </h5>
            <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="search" placeholder="Buscar..." @keyup="searchDocuments"/>
            </span>
          </div>
        </template>
        <Column field="nombre"
                header="Nombre"
                style="padding-left: 2.5rem;"
        >
          <template #body="{data}">
            <span>
              {{ data.nombre }}
            </span>
          </template>
        </Column>
        <Column v-if="$can('pharmasan.oportunidades.licitaciones.herramientas.documentos.access')" header="Acciones"
                style="display: flex; justify-content: end; padding-right: 2.5rem;"
                bodyStyle="display: flex; justify-content: end; padding-right: 2.5rem;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil"
                    class="p-button-rounded mr-2"
                    @click="editProduct(slotProps.data)"
            />
            <Button icon="pi pi-trash"
                    class="p-button-rounded p-button-danger"
                    @click="confirmDeleteDocument(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
      <Paginator v-model:first="first" :rows="10" :totalRecords="paginator.totalRecords" @page="onPage($event)"></Paginator>
    </div>

    <Dialog v-model:visible="documentDialog" :style="{width: '450px'}" header="Agregar Documento" :modal="true"
            class="p-fluid">
      <div class="field">
        <label for="name">Nombre</label>
        <InputText id="name"
                   v-model.trim="document.name"
                   required="true"
                   autofocus
                   class="p-inputtext-sm p-mt-1"
                   :class="{'p-invalid': validationInput(submitted,document.name)}"
        />
        <small class="p-error"
               v-if="submitted && !document.name"
        >
          Nombre es requerido
        </small>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="hideDialog"
        />
        <Button
          label="Guardar"
          icon="pi pi-check"
          class=""
          @click="saveProduct"
        />
      </template>
    </Dialog>

    <Dialog v-model:visible="editDocumentDialog" :style="{width: '450px'}" header="Actualizar Documento" :modal="true"
            class="p-fluid">
      <div class="field">
        <label for="name">Nombre</label>
        <InputText id="name"
                   v-model.trim="document.nombre"
                   required="true"
                   autofocus
                   class="p-inputtext-sm p-mt-1"
                   :class="{'p-invalid': validationInput(submitted,document.name)}"
        />
        <small class="p-error"
               v-if="submitted && !document.nombre"
        >
          Nombre es requerido
        </small>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="editDocumentDialog = false"
        />
        <Button
          label="Actualizar"
          icon="pi pi-check"
          class=""
          @click="confirmEditProduct"
        />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteDocumentsDialog" :style="{width: '450px'}" header="Eliminar Documento" :modal="true"
            class="p-fluid">
      <span>Estas segur@ que deseas eliminar el documento?</span>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="deleteDocumentsDialog = false"
        />
        <Button
          label="Eliminar"
          icon="pi pi-check"
          class=""
          @click="deleteSelectedProduct"
        />
      </template>
    </Dialog>
  </div>
  <Toast/>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import DocumentService from '../../services/DocumentService'
import { useRouter } from 'vue-router'

export default {
  name: 'Documentos',
  components: {},
  setup () {
    /** instacias de las clases */
    const router = useRouter()
    const first = ref(0)
    /** hooks */
    onMounted(() => {
      _DocumentService.value.getDocuments().then((data) => {
        console.log({ data })
        documents.value = data.data.rows
        paginator.value.totalRecords = data.data.count
      })
    })
    /** variables */
      // const toast = useToast()
    const search = ref('')
    const dt = ref()
    const documents = ref()
    const documentDialog = ref(false)
    const editDocumentDialog = ref(false)
    const deleteDocumentsDialog = ref(false)
    const documentDelete = ref('')
    const document = ref({})
    const _DocumentService = ref(new DocumentService())
    const submitted = ref(false)
    const toast = useToast()
    const statuses = ref([
      { label: 'INSTOCK', value: 'instock' },
      { label: 'LOWSTOCK', value: 'lowstock' },
      { label: 'OUTOFSTOCK', value: 'outofstock' }
    ])
    const paginator = ref({
      rows: 10,
      first: 0,
      totalRecords: 0
    })
    /** methods */

    const searchDocuments = (event) => {
      console.log(event)
      _DocumentService.value.getDocuments(0, 10, search.value).then((data) => {
        console.log({ data })
        documents.value = data.data.rows
        paginator.value.totalRecords = data.data.count
        first.value = 0
      })
    }

    const openNew = () => {
      console.log('openNew')
      // document.value = {}
      // submitted.value = false
      documentDialog.value = true
    }
    const hideDialog = () => {
      documentDialog.value = false
      submitted.value = false
    }
    const saveProduct = () => {
      submitted.value = true
      _DocumentService.value.saveDocument({ nombre: document.value.name }).then((data) => {
        console.log({ data })
        documentDialog.value = false
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Documento agregado exitosamente', life: 3000 })
        _DocumentService.value.getDocuments().then((data) => {
          console.log({ data })
          documents.value = data.data.rows
          paginator.value.totalRecords = data.data.count
        })
      })
    }
    const editProduct = (prod) => {
      console.log(prod)
      document.value = { ...prod }
      editDocumentDialog.value = true
    }
    const confirmEditProduct = () => {
      _DocumentService.value.updateDocument(document.value.id, { nombre: document.value.nombre }).then((data) => {
        console.log({ data })
        documentDialog.value = false
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Documento actualizado exitosamente', life: 3000 })
        editDocumentDialog.value = false
        document.value = {}
        _DocumentService.value.getDocuments().then((data) => {
          console.log({ data })
          documents.value = data.data.rows
          paginator.value.totalRecords = data.data.count
        })
      })
    }
    const deleteSelectedProduct = () => {
      _DocumentService.value.deleteDocument(documentDelete.value).then((data) => {
        console.log({ data })
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Documento eliminado exitosamente', life: 3000 })
        deleteDocumentsDialog.value = false
        document.value = {}
        _DocumentService.value.getDocuments().then((data) => {
          console.log({ data })
          documents.value = data.data.rows
          paginator.value.totalRecords = data.data.count
        })
      })
    }
    const createId = () => {
      // let id = ''
      // var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      // for (var i = 0; i < 5; i++) {
      //   id += chars.charAt(Math.floor(Math.random() * chars.length))
      // }
      // return id
    }
    const confirmDeleteDocument = (data) => {
      deleteDocumentsDialog.value = true
      documentDelete.value = data.id
    }

    const validationInput = (a, b) => {
      return a && !b
    }
    const onPage = (event) => {
      _DocumentService.value.getDocuments(event.first, 10, search.value).then((data) => {
        console.log({ data })
        documents.value = data.data.rows
        paginator.value.totalRecords = data.data.count
      })
    }
    /** return */
    return {
      onPage,
      dt,
      documents,
      documentDialog,
      editDocumentDialog,
      deleteDocumentsDialog,
      document,
      submitted,
      statuses,
      openNew,
      hideDialog,
      saveProduct,
      editProduct,
      confirmEditProduct,
      deleteSelectedProduct,
      createId,
      confirmDeleteDocument,
      validationInput,
      routerBack: () => {
        router.go(-1)
      },
      paginator,
      search,
      searchDocuments,
      first
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>

<style>
.p-dialog-mask{
  z-index: 10000 !important;
}
</style>
